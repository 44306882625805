<template>
  <div class="main">
    <div class="hey">
      <img src="@/assets/image/icones/emogi.png" alt="emogi" />
      <h1>Hey!</h1>
      <h4>{{ user.name.split(' ')[0] }}</h4>
    </div>
    <p>
      Tá afim de ganhar mais dinheiro no final do mês? <br />
      Venda estes serviços para o seu cliente!
    </p>
    <Card :cards="card" />
  </div>
</template>

<script>
import Card from "./card.vue";
export default {
  components: { Card },
  data() {
    return {
      card: [
        {
          id: 1214,
          text: `Se o cliente estiver: <br/>  - Pagando Conta Parcelada   <br/>  - Renegociando Dívidas `,
          button: "Proteção Premiada",
          classe: true,
        },
        {
          id: 703,
          text: `Pergunte se o cliente quer consultar suas dívidas para renegocia-las com um super desconto!!`,
          button: "Renegociação de Dividas",
          classe: false,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style scoped>
.main {
  width: 70%;
  color: #fff;
  flex-direction: column;
  display: flex;
  align-items: baseline;
}

.hey {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 56px 0 0; */
}

.hey img {
  width: 173px;
}

h1 {
  font-size: 70px;
}

h4 {
  margin: 0;
  font-size: 40px;
}

p {
  font-size: 18px;
  line-height: 1.6;
  text-align: initial;
}
</style>