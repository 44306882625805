<template>
  <div class="col-md-6 esquerdo text-right py-5">
    <div class="row">
      <div class="col-md-12">
        <p class="titulo_dash">Pedido #{{ pedido.id }}</p>
        <transition-group name="list-complete" tag="div">
          <div
            class="list-complete-item mb-3 text-right "
            v-for="(item, index) in pedido.servicesOrder"
            :key="index + 1"
          >
            <div class="col-md-11 pr-0  mr-0 ml-auto">
              <p class="titulo_dash mb-0">
                {{ item.serviceName }}
                <br />
                Páginas: {{ item.prints }}
                <br />
                <strong>{{ item.value | currency }}</strong>
              </p>
            </div>
            <!-- <div class="col-md-1">
              <a
                href="#"
                @click.prevent="deleteItem(item.orderServiceId)"
                class="navegation_item d-flex align-items-center justify-content-center h-100"
              >
                <icons_custom
                  icone="delete"
                  bg="#868686"
                  wi="40px"
                  hei="40px"
                  class="mr-1"
                />
              </a>
            </div> -->
          </div>
        </transition-group>
        <div class="col-md-3 ml-auto px-0">
          <button @click="search()" class="btn_default" tag="button">
            Add Serviços
          </button>
        </div>
      </div>
    </div>
    <service />
  </div>
</template>

<script>
import HTTP from "@/api_system";
import service from "./card/templateService.vue";
import icons_custom from "@/components/utils/_Icon";
export default {
  components: {
    icons_custom,
    service
  },
  props: ["pedido"],
  data() {
    return {};
  },
  methods: {
    //id, orderId
    deleteItem(id) {
      var url;
      if (this.pedido.servicesOrder.length > 1) {
        url = `services/app/Order/DeleteServiceOnOrder?orderServiceId=${id}&orderId=${this.pedido.id}`;
        HTTP.delete(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(response => {
            if (response.data.result != null) {
              this.$store.commit("set_pedido", response.data.result);
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Serviço Descartado!`
              });
            } else {
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Pedido Descartado!`
              });
              this.$store.commit("set_pedido", "");
              this.$router.push("/pedidos");
            }
          })
          .catch(error => {
            let errorObject = JSON.parse(JSON.stringify(error));
          });
      } else {
        url = `services/app/Order/DiscardOrder?orderId=${this.pedido.id}`;
        HTTP.post(url, "", {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(response => {
            if (response.data.result != null) {
              this.$store.commit("set_pedido", response.data.result);
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Serviço Descartado!`
              });
            } else {
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Pedido Descartado!`
              });
              this.$store.commit("set_pedido", "");
              this.$router.push("/pedidos");
            }
          })
          .catch(error => {
            let errorObject = JSON.parse(JSON.stringify(error));
          });
      }
    },
    search() {
      $(".input_search_list input").click();
    }
  }
};
</script>

<style scoped>
.esquerdo {
  background-color: #357eb8;
}
.titulo_dash {
  line-height: 20px;
}
.btn_default {
  background-color: #e0a638;

  height: 35px;
}
.btn_default:hover {
  background-color: #f38235;
}
.mask {
  background-color: #175d93;
  transition: all 0.2s linear;
}
.mask:hover {
  background-color: #ffffff;
}
</style>
